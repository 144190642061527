export const Translations = [
  {
    id: 'en-US',
    name: 'English',
    translation: {
      title: 'WiFi Card',
      'desc.use':
        'Print a simple card with your WiFi login details. Tape it to the fridge, keep it in your wallet, etc.',
      'desc.privacy':
        'Your WiFi information is never sent to the server. No tracking, analytics, or fingerprinting are used on this website. View the',
      'desc.source': 'source code',
      'wifi.login': 'WiFi Login',
      'wifi.name': 'Network name',
      'wifi.name.hiddenSSID': 'Hidden SSID',
      'wifi.name.placeholder': 'WiFi Network name',
      'wifi.password': 'Password',
      'wifi.password.placeholder': 'Password',
      'wifi.password.hide': 'Hide password',
      'wifi.password.encryption': 'Encryption',
      'wifi.password.encryption.none': 'None',
      'wifi.tip':
        "Point your phone's camera at the QR Code to connect automatically",
      'wifi.alert.name': 'Network name cannot be empty',
      'wifi.alert.password.length.5':
        'Password must be at least 5 characters, or change the encryption to "None"',
      'wifi.alert.password.length.8':
        'Password must be at least 8 characters, or change the encryption to "None"',
      'button.rotate': 'Rotate',
      'button.print': 'Print',
      select: 'Select Language',
    },
  },

  {
    id: 'bn_BD',
    name: 'বাংলা',
    translation: {
      title: 'ওয়াইফাই কার্ড',
      'desc.use':
        'আপনার ওয়াইফাই এর একটা কিউআর কোড বানায় যা দিয়ে পাসওয়ার্ড টাইপ না করে সহজেই ওয়াইফাই কানেক্ট করা যাবে! ',
      'desc.privacy':
        'আপনার ওয়াইফাই এর ড্যাটা কোন সাররাভ্রে পাঠানো হয় না! কোন ধরনের ট্র্যাক/এনালিটিক্স সংগ্রহ করা হয় না! চাইলে সোর্স কোড দেখতে পারেন এখান থেকেঃ',
      'desc.source': 'সোর্স কোড',
      'wifi.login': 'ওয়াইফাই লগিন',
      'wifi.name': 'নেটওয়ার্ক নাম',
      'wifi.name.hiddenSSID': 'লুকানো SSID',
      'wifi.name.placeholder': 'ওয়াইফাই এর এক্সাক্ট নেম',
      'wifi.password': 'পাসওয়ার্ড',
      'wifi.password.placeholder': 'পাসওয়ার্ড',
      'wifi.password.hide': 'পাসওয়ার্ড লুকান',
      'wifi.password.encryption': 'এনক্রিপশন',
      'wifi.password.encryption.none': 'None',
      'wifi.tip':
        "ফোনের ক্যামেরা এখানে ধরলেই ওয়াইফাই কানেক্ট হয়ে যাবে! পুরাতন কিছু ফোনে আলাদা এপ দিয়ে স্ক্যান করা লাগতে পারে! ",
      'wifi.alert.name': 'নেটওয়ার্ক এর নাম খালি রাখা যাবে না! ',
      'wifi.alert.password.length.5':
        'পাসওয়ার্ড সর্বনিম্ন ৫ অক্ষরের হতে হবে অথবা এনক্রিপশন অফ করে দেন! ',
      'wifi.alert.password.length.8':
        'পাসওয়ার্ড সর্বনিম্ন ৮ অক্ষরের হতে হবে অথবা এনক্রিপশন অফ করে দেন! ',
      'button.rotate': 'রোটেট/ঘুরানো',
      'button.print': 'প্রিন্ট',
      select: 'ভাষা নির্ধারণ করুন',
    },
  },
  
].sort((a, b) => {
  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
});
